import { memo } from 'react';
import { useTheme } from 'styled-components';
import { MaskIcon } from '../../../Icons/Icons';
import { ActionBtn, SectionAction } from '../../HomePageV1Style';

interface Props {
  scrollButtonDisable: 'prev' | 'next' | null;
  handleScrollClick: (dir: 'prev' | 'next') => void;
  status: 'everyTime' | 'conditional';
}

function ProductCardsClusterTitleActions({
  scrollButtonDisable,
  handleScrollClick,
  status,
}: Props) {
  const theme = useTheme();
  return (
    <SectionAction status={status}>
      <ActionBtn
        disabled={scrollButtonDisable === 'prev'}
        onClick={
          scrollButtonDisable === 'prev'
            ? () => {}
            : () => handleScrollClick('prev')
        }
      >
        <MaskIcon
          url="/icons/arrow-down-chevron-24.svg"
          height="20px"
          width="20px"
          selected
          color={theme.palette.text.t1}
          margin="0 0 0 0"
          style={{ transform: 'rotate(90deg)' }}
        />
      </ActionBtn>
      <ActionBtn
        disabled={scrollButtonDisable === 'next'}
        onClick={
          scrollButtonDisable === 'next'
            ? () => {}
            : () => handleScrollClick('next')
        }
      >
        <MaskIcon
          url="/icons/arrow-down-chevron-24.svg"
          height="20px"
          width="20px"
          selected
          color={theme.palette.text.t1}
          margin="0 0 0 0"
          style={{ transform: 'rotate(-90deg)' }}
        />
      </ActionBtn>
    </SectionAction>
  );
}

export default memo(ProductCardsClusterTitleActions);
